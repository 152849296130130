body {
  font-family: $TTCommons;
}

* {
  outline: none !important;
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1273px;
  margin: 0 auto;
}

.map-iframe {
  width: 100%;
  height: 688px;
  border: 1px solid #e5e5e5;
}

.map-wrapper {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  animation: fadeIn ease .5s forwards;
  background-color: white;
  z-index: 40;
  display: flex;

  @media (max-width: 992px) {
    height: calc(100% - 62px);
    position: absolute;
  }
}

.map-sidebar {
  width: 370px;
  background-color: white;
  padding: 30px 40px;
  height: 100vh;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: all .3s ease;
    z-index: 80;
    overflow: scroll;
    display: block;
    padding: 0;

    &.show {
      transform: translateY(0);
    }
  }

  .bottom-link {
    width: 100%;
    text-align: center;

    a {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      display: inline-block;
      color: #e81e26;
      border-bottom: 1px solid #e81e26;
      transition: all .3s ease;

      &:hover {
        text-decoration: none;
        border-bottom-color: transparent;
      }

      .if {
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
}

.map-container {
  width: calc(100% - 370px);
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
}

.map-navigation {
  display: flex;
  justify-content: space-between;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 50%;

    > li {
      margin-bottom: 25px;
      padding-right: 20px;
      cursor: pointer;

      .number {
        color: #e81e26;
        font-weight: 400;
        font-size: 23px;
        line-height: 1;
      }

      .name {
        color: #1d2020;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        transition: all .3s ease;
      }

      &.active,
      &:hover {
        .name {
          color: #e81e26;
        }
      }
    }
  }

  @media (max-width: 992px) {
    padding: 60px 50px 0;
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 50px 40px 0;
  }
}

.map-outer {
  position: absolute;
  transform: translateX(0) translateY(0);
  left: 0 !important;
  top: 0 !important;
  transition: all .75s ease;
  cursor: grab;

  &.no-anim {
    transition: none;
  }

  &.ui-draggable-dragging {
    cursor: grabbing;
  }
}

.map-image {
  width: 1000px;

  img {
    max-width: 100%;
  }
}

.map-dot-wrapper {
  position: absolute;
  cursor: pointer;
  z-index: 10;

  &:hover,
  &.hover {
    z-index: 20;

    .map-dot-caption {
      width: auto;
      opacity: 1;
      padding: 14px 20px;
    }
  }
}

.map-dot-number {
  width: 28px;
  height: 28px;
  background-color: #e81e26;
  color: white;
  font-family: $TTCommons;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 900;
  line-height: 33px;
  display: flex;
  justify-content: center;
  animation: pulse ease 1.5s infinite;
  position: relative;
  z-index: 10;
}

.map-dot-caption {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  font-family: $TTCommons;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  background-color: #e81e26;
  color: white;
  border-radius: 8px;
  padding: 0;
  white-space: nowrap;
  z-index: 5;
  width: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .3s ease;

  &.map-dot-caption-left {
    left: auto;
    right: 14px;
  }
}

.show-map-category-mobile {
  display: none;
  color: #1d2020;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  padding: 30px 0;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 50;
  width: 100%;
  background-color: white;

  @media (max-width: 992px) {
    display: block;
  }

  @media (max-width: 767px) {
    padding: 25px 0;
  }
}

.hide-map-category-mobile {
  color: #1d2020;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  padding: 25px 0;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}
