@font-face {
	font-family: "svgfont";
	src: url('../fonts/svgfont.eot');
	src: url('../fonts/svgfont.eot?#iefix') format('eot'), url('../fonts/svgfont.woff') format('woff'), url('../fonts/svgfont.woff2') format('woff2'), url('../fonts/svgfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal; }

$if-download: "\EA01";


%if {
	font-family: "svgfont";
	font-style: normal;
	font-weight: normal;
	text-rendering: auto;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.if, [class^="i-icon-"], [class*=" i-icon-"] {
	@extend %if;
	display: inline-block; }

.if-download:before {
	content: $if-download; }
