@keyframes pulse {
  0% {
	box-shadow: 0 0 0 0 rgba(232, 30, 38, 0.35);
  }
  70% {
	box-shadow: 0 0 0 15px rgba(232, 30, 38, 0);
  }
  100% {
	box-shadow: 0 0 0 0 rgba(232, 30, 38, 0);
  }
}
