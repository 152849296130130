$fontPath: "../fonts" !default;
/*
Fonts mast be next file path - FontName/FontName-Weight.woff
*/
$fontName01: "TTCommons" !default;
$fontVersion01: "1" !default;
$fontFamilies01: Regular, DemiBold, Black;
$fontWeights01: 400, 500, 900;
$fontStyles01: normal, normal, normal;

$TTCommons: "TTCommons", sans-serif;
